import { useContext, useState } from "react";
import Toolbar from "./Toolbar";
import TrainingLoadComposedChart from "../../../components/TrainingLoadComposedChart";
import TrainingLoadAverageBarChart from "../../../components/TrainingLoadAverageBarChart";
import Cards from "../../../components/Cards";
import { AuthContext } from "../../../context/AuthContext";
import PhysicalFitnessReportBarChart from "../../../components/PhysicalFitnessReportBarChart";
import PhysicalFitnessReportRadarChart from "../../../components/PhysicalFitnessReportRadarChart";
import PhysicalFitnessReportTable from "../../../components/PhysicalFitnessReportTable";

export default function Dashboard() {
    const { athlete } = useContext(AuthContext);
    const [dates, setDates] = useState(null);
    const [attributes, setAttributes] = useState({
        left: null,
        right: null
    });
    const [functionName, setFunctionName] = useState("get_composed_chart_data");
    return (
        <div className="flex flex-col gap-8 md:max-xl:flex">
            <Toolbar
                setDates={setDates}
                attributes={attributes}
                setAttributes={setAttributes}
                athlete={athlete}
                dates={dates}
                setFunctionName={setFunctionName}
            />
            <TrainingLoadComposedChart
                className="animate__animated animate__fadeInUp"
                dates={dates}
                athlete={athlete}
                attributes={attributes}
                functionName={functionName}
            />
            <Cards dates={dates} athlete={athlete} attributes={attributes} />
            <TrainingLoadAverageBarChart
                dates={dates}
                athlete={athlete}
                attributes={attributes}
            />
            <PhysicalFitnessReportTable athlete={athlete} dates={dates} />
            <PhysicalFitnessReportBarChart athlete={athlete} dates={dates} />
            <PhysicalFitnessReportRadarChart athlete={athlete} dates={dates} />
        </div>
    );
}
