import { useEffect, useState } from "react";
import { fetchComposedChartData } from "../services/trainingload";
import { gapDates, handleArrayAttributeName } from "../utils/transformArray";

export default function useTrainingLoadData({
    athlete,
    attributes,
    dates,
    initalData = undefined,
    functionName,
    showEmptyDates = false,
    showAttributesName = false
}) {
    const [data, setData] = useState(initalData);

    useEffect(() => {
        async function execute() {
            if (athlete && attributes.left && attributes.right) {
                const { data, error } = await fetchComposedChartData(
                    attributes.left,
                    attributes.right,
                    dates.startDate,
                    dates.endDate,
                    athlete.player_last_name,
                    athlete.player_position,
                    athlete.coach_id,
                    functionName
                );
                if (error) console.log(error);

                let handledData;

                if (showEmptyDates && showAttributesName) {
                    handledData = await handleArrayAttributeName(data);
                    handledData = await gapDates(handledData);
                    setData(handledData);
                    return;
                }

                if (showAttributesName) {
                    handledData = await handleArrayAttributeName(data);
                    setData(handledData);
                    return;
                }

                setData(data);
            }
        }
        execute();
    }, [
        athlete,
        attributes,
        dates,
        functionName,
        showEmptyDates,
        showAttributesName
    ]);

    return {
        data,
        setData
    };
}
