import { supabase } from "../config/supabase-client";

export const fetchTableData = async ({
    page = null,
    rowsPerPage = null,
    coach_id,
    playerLastName = null,
    playerPosition = null,
    tableName,
    searchText,
    sortColumn = "updated_at",
    sortDirection = false
}) => {
    console.log(sortColumn);
    console.log(sortDirection);

    const query = supabase
        .from(tableName)
        .select("*", { count: "exact" })
        .eq("coach_id", coach_id);

    if (playerLastName !== null && playerPosition !== null) {
        query
            .eq("player_last_name", playerLastName)
            .eq("player_position", playerPosition);
    }

    if (searchText !== null) {
        query.or(
            `player_last_name.ilike.%${searchText}%`,
            `session_date.ilike.%${searchText}%`
        );
    }

    if (page !== null && rowsPerPage !== null) {
        query
            .range(page * rowsPerPage, (page + 1) * rowsPerPage - 1)
            .order(sortColumn, { ascending: sortDirection })
            .limit(rowsPerPage);
    }
    const { data, count, error } = await query;
    return { data, count, error };
};
