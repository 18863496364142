import { Modal, Button, Message } from "rsuite";

export default function Delete({ closeModal, deleteFunction }) {
    return (
        <Modal open={true} onClose={closeModal}>
            <div className="flex flex-col gap-5">
                <Message showIcon type="warning">
                    Are you sure want to delete the rows?
                </Message>

                <div className="flex justify-end gap-2">
                    <Button appearance="default" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        appearance="primary"
                        onClick={async () => {
                            deleteFunction().then(() => {
                                closeModal();
                                window.location.reload();
                            });
                        }}
                    >
                        Yes
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
