import { useContext, useState } from "react";
import { supabase } from "../../../config/supabase-client";
import { AuthContext } from "../../../context/AuthContext";
import React from "react";
import usePlayerPosition from "../../../hooks/usePlayerPosition";
import capitalizeWords from "../../../utils/capitalize-words";
import Form from "./Form";

export default function EditAthleteModal({ closeModal, athlete }) {
    const { data: availablePositions } = usePlayerPosition();
    const { profile } = useContext(AuthContext);
    const [playerLastName, setPlayerLastName] = useState(
        athlete.player_last_name
    );
    const [playerPosition, setPlayerPosition] = useState(
        athlete.player_position
    );
    const [birthday, setBirthday] = useState(athlete.birthday);
    const [error, setError] = useState("");

    const handleUpdate = async () => {
        const regex = /^[a-zA-Z\s/]*$/;

        if (regex.test(playerLastName) && regex.test(playerPosition)) {
            const { error: insertError } = await supabase
                .from("athletes")
                .update({
                    player_last_name: capitalizeWords(playerLastName),
                    player_position: capitalizeWords(playerPosition),
                    birthday: birthday,
                })
                .eq("player_last_name", athlete.player_last_name)
                .eq("player_position", athlete.player_position)
                .eq("coach_id", profile.id);

            if (insertError) {
                if (insertError.code === "23505")
                    setError("Athlete already exist!");
                return;
            }
            closeModal();
            window.location.reload();
        }
        setError("Invalid fields");
    };

    return (
        <Form
            availablePositions={availablePositions}
            closeModal={closeModal}
            error={error}
            handleSave={handleUpdate}
            setBirthday={setBirthday}
            setPlayerLastName={setPlayerLastName}
            setPlayerPosition={setPlayerPosition}
            title={"Edit Athlete"}
            athlete={athlete}
        />
    );
}
