import Button from "@mui/material/Button";
import { supabase } from "../../config/supabase-client";

export default function Logout() {
    const handleLogout = async () => {
        try {
            await supabase.auth.signOut();
            window.location.href = "/"; // vai recarregar a pagina por um todo
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Button
                className="text-blue-900"
                onClick={handleLogout}
                variant="text"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="black"
                    className="w-6 h-6"
                >
                    <path d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm5.03 4.72a.75.75 0 010 1.06l-1.72 1.72h10.94a.75.75 0 010 1.5H10.81l1.72 1.72a.75.75 0 11-1.06 1.06l-3-3a.75.75 0 010-1.06l3-3a.75.75 0 011.06 0z" />
                </svg>
                Logout
            </Button>
        </div>
    );
}
