import { useContext, useState } from "react";
import { supabase } from "../../../config/supabase-client";
import { AuthContext } from "../../../context/AuthContext";
import React from "react";
import usePlayerPosition from "../../../hooks/usePlayerPosition";
import capitalizeWords from "../../../utils/capitalize-words";
import Form from "./Form";

export default function InsertAthleteModal({ closeModal }) {
    const { data: availablePositions } = usePlayerPosition();
    const { profile } = useContext(AuthContext);
    const [playerLastName, setPlayerLastName] = useState("");
    const [playerPosition, setPlayerPosition] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [error, setError] = useState("");

    const handleInsert = async () => {
        const regex = /^[a-zA-Z\s/]*$/;
        if (regex.test(playerLastName) && regex.test(playerPosition)) {
            const { error: insertError } = await supabase
                .from("athletes")
                .insert({
                    coach_id: profile.id,
                    player_last_name: capitalizeWords(playerLastName),
                    player_position: capitalizeWords(playerPosition),
                    birthday: birthday
                });

            if (insertError) {
                if (insertError.code === "23505")
                    setError("Athlete already exist!");
                return;
            }
            closeModal();
            window.location.reload();
        }
        setError("Invalid fields");
    };

    return (
        <Form
            availablePositions={availablePositions}
            closeModal={closeModal}
            error={error}
            handleSave={handleInsert}
            setBirthday={setBirthday}
            setPlayerLastName={setPlayerLastName}
            setPlayerPosition={setPlayerPosition}
            title={"Insert Athlete"}
        />
    );
}
