export default function useSessionStorage(name) {
    function getSessionStorage() {
        const session = sessionStorage.getItem(name);
        if (session != null) {
            return JSON.parse(session);
        }
        return null;
    }

    function setSessionStorage(item) {
        sessionStorage.setItem(name, JSON.stringify(item));
    }

    function removeSessionStorage() {
        sessionStorage.removeItem(name);
    }

    return { getSessionStorage, setSessionStorage, removeSessionStorage };
}
