import Table from "../../../components/Table";
import useFetchTable from "../../../hooks/useFetchTable";
import COLUMNS from "./COLUMNS";

export default function PhysicalFitness() {
    const { data, options } = useFetchTable({
        tablename: "physical_fitness",
        deleteFunction: null
    });

    return (
        <div>
            <Table
                data={data}
                options={options}
                title={"Results"}
                columnsName={COLUMNS}
            />
        </div>
    );
}
