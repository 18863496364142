const COLUMNS = [
    {
        name: "id",
        label: "Id",
        type: "text",
        options: {
            display: false,
            viewColumns: false,
            filter: false
        }
    },
    {
        name: "player_last_name",
        label: "Player Last Name",
        type: "text",
        options: {
            display: true,
            sort: false
        }
    },
    {
        name: "player_position",
        label: "Player Position",
        type: "text (if no position assigned, type 'none')",
        options: {
            display: true,
            sort: false
        }
    },
    {
        name: "session_date",
        label: "Session Date",
        type: "text (yyyy-mm-dd)",
        options: {
            display: true
        }
    },
    {
        name: "weight",
        label: "Weight",
        type: "number",
        options: {
            display: true
        }
    },
    {
        name: "height",
        label: "Height",
        type: "number",
        options: {
            display: true
        }
    },
    {
        name: "fat",
        label: "Fat",
        type: "number",
        options: {
            display: true
        }
    },
    {
        name: "bmi",
        label: "Bmi",
        type: "number",
        options: {
            display: false
        }
    },
    {
        name: "fp",
        label: "Fp",
        type: "number",
        options: {
            display: false
        }
    },
    {
        name: "yo_yo_dist",
        label: "Yo Yo Dist",
        type: "number",
        options: {
            display: false
        }
    },
    {
        name: "yo_yo_vo",
        label: "Yo Yo Vo",
        type: "number",
        options: {
            display: false
        }
    },
    {
        name: "cmj",
        label: "Cmj",
        type: "number",
        options: {
            display: false
        }
    },
    {
        name: "sbj",
        label: "Sbj",
        type: "number",
        options: {
            display: false
        }
    },
    {
        name: "hop_dist",
        label: "Hop Dist",
        type: "number",
        options: {
            display: false
        }
    }
];
export default COLUMNS;
