const attributeLeft = [
    "session_pse",
    "session_duration",
    "session_pontuation",
    "distance_total",
    "distance_per_min",
    "speed_intensity",
    "high_speed_running",
    "high_speed_running_per_minute",
    "distance_z4_to_z6",
    "sprints",
    "distance_z6",
    "high_metabolic_power_distance",
    "high_metabolic_power_distance_per_minute",
    "average_metabolic_power",
    "dynamic_stress_load",
    "dynamic_stress_load_z5_to_z6",
    "accelerations",
    "accelerations_z3_to_z6",
    "accelerations_z5_to_z6",
    "decelerations",
    "deceleration_z3_to_z6",
    "deceleration_z5_to_z6",
    "impacts",
    "impacts_z3_to_z6",
    "impacts_z5_to_z6",
    "heart_rate_exertion",
    "fatigue_index",
    "percieved_exertion",
    "equivalent_metabolic_distance",
    "metabolic_distance_zonal",
    "max_hr",
    "max_speed",
    "energyexpenditure",
    "duration_decimal",
    "explosive_distance"
].map((item) => ({ label: item, value: item }));

const attributeRight = [
    "session_pse",
    "session_duration",
    "session_pontuation",
    "distance_total",
    "distance_per_min",
    "speed_intensity",
    "high_speed_running",
    "high_speed_running_per_minute",
    "distance_z4_to_z6",
    "sprints",
    "distance_z6",
    "high_metabolic_power_distance",
    "high_metabolic_power_distance_per_minute",
    "average_metabolic_power",
    "dynamic_stress_load",
    "dynamic_stress_load_z5_to_z6",
    "accelerations",
    "accelerations_z3_to_z6",
    "accelerations_z5_to_z6",
    "decelerations",
    "deceleration_z3_to_z6",
    "deceleration_z5_to_z6",
    "impacts",
    "impacts_z3_to_z6",
    "impacts_z5_to_z6",
    "heart_rate_exertion",
    "fatigue_index",
    "percieved_exertion",
    "equivalent_metabolic_distance",
    "metabolic_distance_zonal",
    "max_hr",
    "max_speed",
    "energyexpenditure",
    "duration_decimal",
    "explosive_distance"
].map((item) => ({ label: item, value: item }));

module.exports = {
    attributeLeft,
    attributeRight
};
