import { Modal, Button, Message } from "rsuite";
import { MuiFileInput } from "mui-file-input";
import { useContext, useState } from "react";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { convertXlsxToJson } from "../../../utils/handle-data-xlsx-csv";
import { AuthContext } from "../../../context/AuthContext";
import useSessionStorage from "../../../hooks/useSessionStorage";
import { fetchAthletes } from "../../../services/athletes";
import { getNonExistentAthletes } from "./helper-functions";
import NewAthleteFoundModal from "./NewAthleteFoundModal";

import styled from "styled-components";

export default function ModalInsertXlsxTrainningLoad({ closeModal, insertFunction }) {
    const { profile } = useContext(AuthContext);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(undefined);
    const [dataToBeInserted, setDataToDoInserted] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { removeSessionStorage } = useSessionStorage("topAthletes");
    const [newAthletesModal, setNewAthletesModal] = useState(null);
    const [fileIsValid, setFileIsValid] = useState(true);

    const updateMessage = (severity, msg) => {
        setMessage({ severity, msg });
    };

    const handleSelectFile = (file) => {
        // this if is in case the user has one file already and then click the "x" button, so it will clean the message and file in the input
        if (!file) {
            setFile(null);
            setMessage(undefined);
            return;
        }

        setFile(file);
        setLoading(true);

        convertXlsxToJson(file)
            .then((dataToBeInserted) => {
                setDataToDoInserted(dataToBeInserted);
                return dataToBeInserted;
            })
            .then(async (dataToBeInserted) => {
                await getNewAthletes(dataToBeInserted);
                setLoading(false);
                setFileIsValid(false);
            })
            .catch((error) => {
                updateMessage("error", error.message);
                setLoading(false);
            });
    };

    const handleImport = async () => {
        setLoading(true);
    
        const mappedData = dataToBeInserted.map((item) => {
            const playerName = item.player_last_name;
            const playerPosition = item.player_position;
            const sessionDate = item.session_date;
            const sessionDuration = item.session_duration;
            const sessionPse = item.session_pse;
            const sessionPontuation = item.session_pontuation;
            const sessionDayOfWeek = item.session_day_of_week;
            const sessionWeekNumber = item.session_week_number;
            const sessionType = item.session_type;
            const drillDate = item.drill_date;
            const drillTitle = item.drill_title;
            const distanceTotal = item.distance_total;
            const distancePerMin = item.distance_per_min;
            const speedIntensity = item.speed_intensity;
            const highSpeedRunning = item.high_speed_running;
            const highSpeedRunningPerMinute = item.high_speed_running_per_minute;
            const distanceZ4ToZ6 = item.distance_z4_to_z6;
            const sprints = item.sprints;
            const distanceZ6 = item.distance_z6;
            const highMetabolicPowerDistance = item.high_metabolic_power_distance;
            const highMetabolicPowerDistancePerMinute = item.high_metabolic_power_distance_per_minute;
            const averageMetabolicPower = item.average_metabolic_power;
            const dynamicStressLoad = item.dynamic_stress_load;
            const dynamicStressLoadZ5ToZ6 = item.dynamic_stress_load_z5_to_z6;
            const accelerations = item.accelerations;
            const accelerationsZ3ToZ6 = item.accelerations_z3_to_z6;
            const accelerationsZ5ToZ6 = item.accelerations_z5_to_z6;
            const decelerations = item.decelerations;
            const decelerationZ3ToZ6 = item.deceleration_z3_to_z6;
            const decelerationZ5ToZ6 = item.deceleration_z5_to_z6;
            const impacts = item.impacts;
            const impactsZ3ToZ6 = item.impacts_z3_to_z6;
            const impactsZ5ToZ6 = item.impacts_z5_to_z6;
            const heartRateExertion = item.heart_rate_exertion;
            const timeInRedZone = item.time_in_red_zone;
            const timeInRedZonePerMinute = item.time_in_red_zone_per_minute;
            const fatigueIndex = item.fatigue_index;
            const perceivedExertion = item.percieved_exertion;
            const equivalentMetabolicDistance = item.equivalent_metabolic_distance;
            const metabolicDistanceZonal = item.metabolic_distance_zonal;
            const maxHr = item.max_hr;
            const maxSpeed = item.max_speed;
            const drillStart = item.drill_start;
            const drillEnd = item.drill_end;
            const energyExpenditure = item.energyexpenditure;
            const timeInZone6 = item.time_in_zone_6;
            const durationDecimal = item.duration_decimal;
            const explosiveDistance = item.explosive_distance;
            const includeExclude = item.includeexclude;
            const season = item.season;
            const sessionTypeOverride = item.session_type_override;
        
            return {
                player_last_name: playerName,
                player_position: playerPosition,
                session_date: sessionDate,
                session_duration: sessionDuration,
                session_pse: sessionPse,
                session_pontuation: sessionPontuation,
                session_day_of_week: sessionDayOfWeek,
                session_week_number: sessionWeekNumber,
                session_type: sessionType,
                drill_date: drillDate,
                drill_title: drillTitle,
                distance_total: distanceTotal,
                distance_per_min: distancePerMin,
                speed_intensity: speedIntensity,
                high_speed_running: highSpeedRunning,
                high_speed_running_per_minute: highSpeedRunningPerMinute,
                distance_z4_to_z6: distanceZ4ToZ6,
                sprints: sprints,
                distance_z6: distanceZ6,
                high_metabolic_power_distance: highMetabolicPowerDistance,
                high_metabolic_power_distance_per_minute: highMetabolicPowerDistancePerMinute,
                average_metabolic_power: averageMetabolicPower,
                dynamic_stress_load: dynamicStressLoad,
                dynamic_stress_load_z5_to_z6: dynamicStressLoadZ5ToZ6,
                accelerations: accelerations,
                accelerations_z3_to_z6: accelerationsZ3ToZ6,
                accelerations_z5_to_z6: accelerationsZ5ToZ6,
                decelerations: decelerations,
                deceleration_z3_to_z6: decelerationZ3ToZ6,
                deceleration_z5_to_z6: decelerationZ5ToZ6,
                impacts: impacts,
                impacts_z3_to_z6: impactsZ3ToZ6,
                impacts_z5_to_z6: impactsZ5ToZ6,
                heart_rate_exertion: heartRateExertion,
                time_in_red_zone: timeInRedZone,
                time_in_red_zone_per_minute: timeInRedZonePerMinute,
                fatigue_index: fatigueIndex,
                percieved_exertion: perceivedExertion,
                equivalent_metabolic_distance: equivalentMetabolicDistance,
                metabolic_distance_zonal: metabolicDistanceZonal,
                max_hr: maxHr,
                max_speed: maxSpeed,
                drill_start: drillStart,
                drill_end: drillEnd,
                energyexpenditure: energyExpenditure,
                time_in_zone_6: timeInZone6,
                duration_decimal: durationDecimal,
                explosive_distance: explosiveDistance,
                includeexclude: includeExclude,
                season: season,
                session_type_override: sessionTypeOverride,
                coach_id: profile.id
            };
        });

        const { error } = await insertFunction(mappedData);
    
        if (error) {
            console.log(error);
            updateMessage("error", error.message);
            setLoading(false);
        } else {
            removeSessionStorage();
            updateMessage("success", "Data inserted!");
            closeModal();
            window.location.reload();
        }
    };

    const getNewAthletes = async (dataToBeInserted) => {
        const { data: existingAthletes, error } = await fetchAthletes(
            profile.id
        );
        if (error) console.log(error);

        const newAthletes = await getNonExistentAthletes(
            existingAthletes,
            dataToBeInserted
        );

        if (newAthletes.length !== 0) {
            setNewAthletesModal(
                <NewAthleteFoundModal
                    newAthletes={newAthletes}
                    profile={profile}
                    closeModal={() => {
                        setNewAthletesModal(null);
                    }}
                />
            );
        }
    };

    return (
        <>
            {newAthletesModal}
            <Modal open={true} onClose={closeModal} backdrop={'static'}>
                <div className="flex flex-col gap-5 jus">
                    <Modal.Header>
                        <Modal.Title>Import XLSX File</Modal.Title>
                    </Modal.Header>

                    {message && (
                        <Message showIcon type={message.severity}>
                            {message.msg}
                        </Message>
                    )}

                    <CustomStyledWrapper>
                        <MuiFileInput
                            className="MuiInputAdornment-positionEnd"
                            value={file}
                            onChange={handleSelectFile}
                        />
                    </CustomStyledWrapper>

                    <div className="flex justify-end gap-2">
                        {loading && (
                            <div className="icon-example-list">
                                <SpinnerIcon
                                    pulse
                                    style={{ fontSize: "2em" }}
                                />
                            </div>
                        )}

                        <Button appearance="default" onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={handleImport}
                            disabled={fileIsValid}
                        >
                            Import
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

// to fix position of the file inside the MuiFileInput
const CustomStyledWrapper = styled.div`
    display: grid;

    & > div > div > div:nth-child(3) {
        margin-left: auto;
    }
`;
