import { supabase } from "../config/supabase-client";

export const updateTable = async ({ tableName, columnName, newValue, id }) => {
    const { data, error } = await supabase
        .from(tableName)
        .update({ [columnName]: newValue })
        .eq("id", id)
        .select();
    return { data, error };
};
