import Cards from "../../../components/Cards";
import { useState } from "react";
import "rsuite/dist/rsuite.min.css";
import TrainingLoadAverageBarChart from "../../../components/TrainingLoadAverageBarChart";
import RightCard from "./RightCard";
import Toolbar from "./Toolbar";
import TrainingLoadComposedChart from "../../../components/TrainingLoadComposedChart";
import PhysicalFitnessReportBarChart from "../../../components/PhysicalFitnessReportBarChart";
import PhysicalFitnessReportRadarChart from "../../../components/PhysicalFitnessReportRadarChart";
import PhysicalFitnessReportTable from "../../../components/PhysicalFitnessReportTable";

export default function Dashboard() {
    const [dates, setDates] = useState(null);
    const [athlete, setAthlete] = useState(undefined);
    const [attributes, setAttributes] = useState({
        left: null,
        right: null
    });
    const [functionName, setFunctionName] = useState("get_composed_chart_data"); // supabase postgres name function

    return (
        <div className="flex flex-col gap-14 md:max-xl:flex">
            <Toolbar
                setAthlete={setAthlete}
                setDates={setDates}
                attributes={attributes}
                setAttributes={setAttributes}
                athlete={athlete}
                dates={dates}
                setFunctionName={setFunctionName}
            />
            <TrainingLoadComposedChart
                className="animate__animated animate__fadeInUp"
                dates={dates}
                athlete={athlete}
                attributes={attributes}
                functionName={functionName}
            />

            <Cards dates={dates} athlete={athlete} attributes={attributes} />
            <div className="grid lg:grid-cols-2   animate__animated animate__fadeInUp gap-8">
                <TrainingLoadAverageBarChart
                    dates={dates}
                    athlete={athlete}
                    attributes={attributes}
                />
                <RightCard />
            </div>
            <PhysicalFitnessReportTable athlete={athlete} dates={dates} />
            <div className="grid lg:grid-cols-2   animate__animated animate__fadeInUp gap-8">
                <PhysicalFitnessReportBarChart
                    athlete={athlete}
                    dates={dates}
                />
                <PhysicalFitnessReportRadarChart
                    athlete={athlete}
                    dates={dates}
                />
            </div>
        </div>
    );
}
