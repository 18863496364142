import "animate.css";
import useTrainingLoadData from "../hooks/useTrainingLoadData";

export default function Cards({ athlete, attributes, dates }) {
    const { data } = useTrainingLoadData({
        dates,
        athlete,
        attributes,
        initalData: undefined,
        functionName: "get_cards_stats"
    });
    return (
        <div className=" animate__animated animate__fadeInUp  grid lg:grid-cols-3  gap-3  ">
            {data ? (
                <>
                    <Card
                        title="Maximum"
                        attributeOne={attributes.left}
                        valueOne={data[0].max_attribute_one}
                        attributeTwo={attributes.right}
                        valueTwo={data[0].max_attribute_two}
                    />
                    <Card
                        title="Average"
                        attributeOne={attributes.left}
                        valueOne={data[0].avg_attribute_one}
                        attributeTwo={attributes.right}
                        valueTwo={data[0].avg_attribute_two}
                    />
                    <Card
                        title="Minimum"
                        attributeOne={attributes.left}
                        valueOne={data[0].min_attribute_one}
                        attributeTwo={attributes.right}
                        valueTwo={data[0].min_attribute_two}
                    />
                </>
            ) : (
                <>
                    <Card title="Maximum" />
                    <Card title="Average" />
                    <Card title="Minimum" />
                </>
            )}
        </div>
    );
}

function Card({ title, attributeOne, valueOne, attributeTwo, valueTwo }) {
    return (
        <div className="bg-white drop-shadow rounded p-8 ">
            <h4 className="text-blue-900">{title}</h4>
            <hr />
            {attributeOne ? (
                <>
                    <p className="text-black text-lg">
                        {attributeOne}: {valueOne}
                    </p>
                    <p className="text-black  text-lg">
                        {attributeTwo}: {valueTwo}
                    </p>
                </>
            ) : null}
        </div>
    );
}
