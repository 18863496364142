import React, { useState, useContext, useEffect } from "react";
import {
    PieChart,
    Pie,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell
} from "recharts";
import { getTopAthletesWithMostData } from "../../../services/trainingload";
import { AuthContext } from "../../../context/AuthContext";
import useSessionStorage from "../../../hooks/useSessionStorage";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default function RightCard() {
    const [data, setData] = useState([]);
    const { profile } = useContext(AuthContext);
    const { getSessionStorage, setSessionStorage } =
        useSessionStorage("topAthletes");

    useEffect(() => {
        async function execute() {
            let cachedData = await getSessionStorage();
            if (cachedData) {
                setData(cachedData);
                return;
            }
            const { data, error } = await getTopAthletesWithMostData(
                profile.id
            );
            if (error) return;
            setSessionStorage(data);
            setData(data);
        }
        execute();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    return (
        <div className="space-y-5">
            <h4 className="text-blue-900 text-center">Top athletes</h4>
            <ResponsiveContainer
                width={"100%"}
                height={400}
                className="justify-self-center self-center"
            >
                <PieChart>
                    <Pie
                        dataKey="total_data"
                        nameKey="player_last_name"
                        startAngle={180}
                        endAngle={0}
                        data={data}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#413ea0"
                        label
                        tooltip={({ payload }) => {
                            if (payload && payload.length > 0) {
                                const { player_last_name, total_data } =
                                    payload[0];
                                return (
                                    <div>
                                        <div>{player_last_name}</div>
                                        <div>Total Data: {total_data}</div>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}
