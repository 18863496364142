import { useState } from "react";
import Table from "../../../components/Table";
import useFetchTable from "../../../hooks/useFetchTable";
import useColumns from "../../coach/training-load/useColumns";
import HelpOutlineIcon from "@rsuite/icons/HelpOutline";
import HelpModal from "./HelpModal";

export default function TrainingLoad() {
    const { data, options } = useFetchTable({
        tablename: "training_load",
        deleteFunction: null
    });
    const [modal, setModal] = useState(null);
    const { columns } = useColumns({
        setModal,
        closeModal: () => {
            setModal(null);
        }
    });

    return (
        <div className="flex flex-col gap-2">
            {modal}
            <HelpOutlineIcon
                onClick={() => {
                    setModal(
                        <HelpModal
                            closeModal={() => {
                                setModal(null);
                            }}
                        />
                    );
                }}
                className="hover:bg-gray-200 rounded-md hover:cursor-pointer self-end"
                style={{ fontSize: "2em" }}
            />
            <Table
                data={data}
                options={options}
                title={"Results"}
                columnsName={columns}
            />
        </div>
    );
}
