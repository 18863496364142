import { supabase } from "../config/supabase-client";

export const getTopAthletesWithMostData = async (coach_id) => {
    const { data, error } = await supabase.rpc("gettopathletes", {
        coach_id: coach_id
    });
    return { data, error };
};

export const insertTrainingLoad = async (dataToInsert) => {
    const { error } = await supabase.from("training_load").insert(dataToInsert);
    return { error };
};

export const fetchComposedChartData = async (
    attributeOne,
    attributeTwo,
    startDate,
    endDate,
    playerLastName,
    playerPosition,
    coach_id,
    functionName
) => {
    const { data, error } = await supabase.rpc(functionName, {
        in_attribute_one: attributeOne,
        in_attribute_two: attributeTwo,
        in_start_date: startDate,
        in_end_date: endDate,
        in_player_last_name: playerLastName,
        in_player_position: playerPosition,
        in_coach_id: coach_id
    });
    return { data, error };
};
