import * as XLSX from "xlsx";
import capitalizeWords from "./capitalize-words";
import dateValidation from "./date-validation";

function nameAndPositionValidation(index, ...values) {
    values.forEach((value) => {
        if (value === null || value === undefined)
            throw new Error(
                `Invalid player_last_name or player_position ${
                    index ? `at row ${index}` : ""
                }`
            );
    });
}

export function convertXlsxToJson(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            try {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, {
                    type: "array",
                    cellDates: true,
                    cellText: false
                });

                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                    header: 1,
                    raw: false,
                    dateNF: "yyyy-mm-dd"
                });

                const headers = jsonData[0];
                const formattedHeaders = headers.map((header) =>
                    header.toLowerCase().replace(/\s+/g, "_")
                );

                const dataArray = jsonData.slice(1); // Remove the header row

                if (dataArray.length === 0) throw new Error(`Empty file`);

                const dataToBeInserted = dataArray.map((row, index) => {
                    const rowData = {};

                    // transform array of arrays in array of objects
                    formattedHeaders.forEach((header, index) => {
                        rowData[header] = row[index];
                    });

                    const { player_last_name, player_position, session_date } =
                        rowData;

                    // validations
                    nameAndPositionValidation(
                        index + 2,
                        player_last_name,
                        player_position
                    );
                    dateValidation(index + 2, session_date);

                    rowData.player_last_name =
                        capitalizeWords(player_last_name);
                    rowData.player_position = capitalizeWords(player_position);

                    return rowData;
                });

                resolve(dataToBeInserted);
            } catch (error) {
                reject(error);
            }
        };

        reader.onerror = function (event) {
            reject(event.target.error);
        };

        reader.readAsArrayBuffer(file);
    });
}
