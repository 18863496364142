export default function useLocalStorage(name) {
    function getLocalStorage() {
        const local = localStorage.getItem(name);
        if (local != null) {
            return JSON.parse(local);
        }
        return null;
    }

    function setLocalStorage(item) {
        localStorage.setItem(name, JSON.stringify(item));
    }

    function removeLocalStorage() {
        return localStorage.removeItem(name);
    }

    return { getLocalStorage, setLocalStorage, removeLocalStorage };
}
