import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { fetchAthletes } from "../services/athletes";

export default function useAthletes() {
    const [athletes, setAthletes] = useState([]);
    const { profile } = useContext(AuthContext);

    useEffect(() => {
        async function execute() {
            if (profile.id) {
                const { data, error } = await fetchAthletes(profile.id);
                if (error) console.log("Error fetching athletes data:", error);
                else setAthletes(data);
            }
        }
        execute();
    }, [profile, setAthletes]);

    return {
        athletes
    };
}
