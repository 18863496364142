import { Modal, Button } from "rsuite";

import { insertAthletes } from "../../../services/athletes";

export default function NewAthleteFoundModal({
    newAthletes,
    closeModal,
    profile
}) {
    return (
        <Modal open={true} onClose={closeModal}>
            <div className="flex flex-col gap-5 jus">
                <Modal.Header>
                    <Modal.Title>
                        New Athletes found will be inserted to the Atheltes
                        table
                    </Modal.Title>
                </Modal.Header>
                {newAthletes.map((athlete, index) => {
                    return (
                        <span key={index}>
                            {athlete.player_last_name} -{" "}
                            {athlete.player_position}
                        </span>
                    );
                })}
                <div className="flex justify-end space-x-2 mt-10">
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button
                        appearance="primary"
                        onClick={() => {
                            const newAthletesWithCoachId = newAthletes.map(
                                (athlete) => ({
                                    ...athlete,
                                    coach_id: profile.id
                                })
                            );

                            insertAthletes(newAthletesWithCoachId)
                                .then(() => {
                                    closeModal();
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }}
                    >
                        Agree
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
