import {
    Modal,
    AutoComplete,
    Input,
    Button,
    DatePicker,
    Message
} from "rsuite";

export default function Form({
    closeModal,
    error,
    setPlayerLastName,
    availablePositions,
    setPlayerPosition,
    setBirthday,
    handleSave,
    title,
    athlete = null
}) {
    return (
        <Modal open={true} onClose={closeModal} backdrop={'static'}>
            <div className="flex flex-col gap-5">
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                {error && (
                    <Message showIcon type="error">
                        {error}
                    </Message>
                )}

                <Input
                    size="lg"
                    placeholder="Player Last Name"
                    onChange={(value) => {
                        setPlayerLastName(value);
                    }}
                    defaultValue={athlete ? athlete.player_last_name : null}
                />
                {availablePositions && (
                    <AutoComplete
                        size="lg"
                        data={availablePositions}
                        placeholder="Player Position"
                        onChange={(value) => {
                            setPlayerPosition(value);
                        }}
                        defaultValue={athlete ? athlete.player_position : null}
                    />
                )}
                <DatePicker
                    size="lg"
                    onOk={async (event) => {
                        console.log(event);
                        const formattedDate = event
                            .toISOString()
                            .substring(0, 10);
                        setBirthday(formattedDate);
                    }}
                    defaultValue={
                        athlete && athlete.birthday
                            ? new Date(athlete.birthday)
                            : null
                    }
                />
                <div className="flex justify-end gap-2">
                    <Button appearance="default" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button appearance="primary" onClick={handleSave}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
