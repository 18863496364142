import { useState } from "react";
import UpdateTableFieldModal from "../../../components/UpdateTableFieldModal";

export default function useColumns({ setModal, closeModal }) {
    function customBodyRender(value, tableMeta) {
        if (value)
            return (
                <span
                    className="hover:cursor-pointer hover:underline  "
                    onClick={() => {
                        let id = tableMeta.rowData[0];
                        let columnName = tableMeta.columnData.name;
                        setModal(
                            <UpdateTableFieldModal
                                closeModal={closeModal}
                                defaultValue={value}
                                columnName={columnName}
                                id={id}
                                tableName={"physical_fitness"}
                            />
                        );
                    }}
                >
                    {value}
                </span>
            );

        return (
            <span
                className="hover:cursor-pointer hover:underline  "
                onClick={() => {
                    let id = tableMeta.rowData[0];
                    let columnName = tableMeta.columnData.name;
                    setModal(
                        <UpdateTableFieldModal
                            closeModal={closeModal}
                            columnName={columnName}
                            id={id}
                            tableName={"physical_fitness"}
                        />
                    );
                }}
            >
                N/A
            </span>
        );
    }

    const [columns] = useState([
        {
            name: "id",
            label: "Id",
            type: "text",
            options: {
                display: false,
                viewColumns: false,
                filter: false
            }
        },
        {
            name: "player_last_name",
            label: "Player Last Name",
            type: "text",
            options: {
                display: true,
                sort: false
            }
        },
        {
            name: "player_position",
            label: "Player Position",
            type: "text (if no position assigned, type 'none')",
            options: {
                display: true,
                sort: false
            }
        },
        {
            name: "session_date",
            label: "Session Date",
            type: "text (yyyy-mm-dd)",
            options: {
                display: true,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "weight",
            label: "Weight",
            type: "number",
            options: {
                display: true,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "height",
            label: "Height",
            type: "number",
            options: {
                display: true,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "fat",
            label: "Fat",
            type: "number",
            options: {
                display: true,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "bmi",
            label: "Bmi",
            type: "number",
            options: {
                display: false,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "fp",
            label: "Fp",
            type: "number",
            options: {
                display: false,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "yo_yo_dist",
            label: "Yo Yo Dist",
            type: "number",
            options: {
                display: false,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "yo_yo_vo",
            label: "Yo Yo Vo",
            type: "number",
            options: {
                display: false,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "cmj",
            label: "Cmj",
            type: "number",
            options: {
                display: false,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "sbj",
            label: "Sbj",
            type: "number",
            options: {
                display: false,
                customBodyRender: customBodyRender
            }
        },
        {
            name: "hop_dist",
            label: "Hop Dist",
            type: "number",
            options: {
                display: false,
                customBodyRender: customBodyRender
            }
        }
    ]);
    return {
        columns
    };
}
