import { supabase } from "../config/supabase-client";

export const insertPhysicalFitness = async (dataToInsert) => {
    const { error } = await supabase
        .from("physical_fitness")
        .insert(dataToInsert);

    return { error };
};

export const fetchReportChartStats = async (
    playerLastName,
    playerPosition,
    startDate,
    endDate,
    coach_id,
    functionName
) => {
    const { data, error } = await supabase.rpc(functionName, {
        in_player_last_name: playerLastName,
        in_player_position: playerPosition,
        in_start_date: startDate,
        in_end_date: endDate,
        in_coach_id: coach_id
    });
    return { data, error };
};
