import Nav from "./Nav";

export default function Content({ selected, sections }) {
    return (
        <div className="col-span-10  bg-slate-100">
            <Nav />
            <div className="p-8 ">
                {sections.filter((m) => m.id === selected)[0].content}
            </div>
        </div>
    );
}
