import {
    ResponsiveContainer,
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Tooltip
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import usePhysicalFitnessData from "../hooks/usePhysicalFitnessData";
import { useCallback } from "react";

export default function PhysicalFitnessReportRadarChart({ athlete, dates }) {
    const handleDataModification = useCallback((fetchedData) => {
        if (fetchedData.length !== 0) {
            let data = Object.entries(fetchedData[0]).map(([key, value]) => ({
                name: key,
                variation: value
            }));
            return data;
        }
    }, []);

    const { data } = usePhysicalFitnessData({
        athlete,
        dates,
        functionName: "get_report_chart_stats",
        handleDataModification: handleDataModification
    });

    return (
        <div className="flex flex-col items-center gap-3  justify-self-center w-5/6">
            <h4 className="text-blue-900">Z-score</h4>
            <ResponsiveContainer width="100%" height={400}>
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
                    <Tooltip content={<CustomTooltip />} />
                    <PolarGrid />
                    <PolarAngleAxis dataKey="name" />
                    <PolarRadiusAxis />
                    <Radar
                        dataKey="variation"
                        stroke="#8884d8"
                        fill="#413ea0"
                        fillOpacity={0.6}
                    />
                </RadarChart>
            </ResponsiveContainer>
        </div>
    );
}
