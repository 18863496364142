import { Table } from "rsuite";
import usePhysicalFitnessData from "../hooks/usePhysicalFitnessData";
import { useCallback } from "react";

const { Column, HeaderCell, Cell } = Table;

export default function PhysicalFitnessReportTable({ athlete, dates }) {
    const handleDataModification = useCallback((fetchedData) => {
        if (fetchedData.length !== 0) {
            let firstSession = fetchedData.shift();
            let lastSession = fetchedData.pop();

            firstSession.session_date = `First Analysis: ${firstSession.session_date}`;
            lastSession.session_date = `Last Analysis: ${lastSession.session_date}`;

            return [firstSession, lastSession];
        }
    }, []);

    const { data } = usePhysicalFitnessData({
        athlete,
        dates,
        functionName: "get_report_table_stats",
        initalData: [],
        handleDataModification: handleDataModification
    });

    return (
        <Table
            data={data}
            bordered
            cellBordered
            onSortColumn={(sortColumn, sortType) => {
                console.log(sortColumn, sortType);
            }}
        >
            <Column width={300} align="center">
                <HeaderCell></HeaderCell>
                <Cell className="text-base" dataKey="session_date" />
            </Column>

            <Column flexGrow={1} align="center">
                <HeaderCell>Weight (kg)</HeaderCell>
                <Cell className="text-base" dataKey="weight" />
            </Column>

            <Column flexGrow={1} align="center">
                <HeaderCell>Height (cm)</HeaderCell>
                <Cell className="text-base" dataKey="height" />
            </Column>

            <Column flexGrow={1} align="center">
                <HeaderCell>Fat (%)</HeaderCell>
                <Cell className="text-base" dataKey="fat" />
            </Column>
        </Table>
    );
}
