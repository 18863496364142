/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { fetchReportChartStats } from "../services/physicalfitness";

export default function usePhysicalFitnessData({
    athlete,
    dates,
    functionName,
    initalData = [
        {
            name: "sbj"
        },
        {
            name: "height"
        },
        {
            name: "weight"
        },
        {
            name: "cmj"
        },
        {
            name: "bmi"
        }
    ],
    handleDataModification
}) {
    const [data, setData] = useState(initalData);

    useEffect(() => {
        async function execute() {
            if (athlete && dates) {
                const { data, error } = await fetchReportChartStats(
                    athlete.player_last_name,
                    athlete.player_position,
                    dates.startDate,
                    dates.endDate,
                    athlete.coach_id,
                    functionName
                );
                if (data.length === 0) {
                    setData(initalData);
                    return;
                }
                if (error) console.log(error);
                setData(handleDataModification(data));
            }
        }
        execute();
    }, [athlete, dates, functionName, handleDataModification]);

    return {
        data,
        setData
    };
}
