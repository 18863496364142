/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import usePhysicalFitnessData from "../hooks/usePhysicalFitnessData";

export default function PhysicalFitnessReportBarChart({ athlete, dates }) {
    let xMin = -5;
    let xMax = 5;

    const handleDataModification = useCallback((fetchedData) => {
        if (fetchedData.length !== 0) {
            let data = Object.entries(fetchedData[0]).map(([key, value]) => ({
                name: key,
                variation: value
            }));
            const minVariation = Math.min(
                ...data.map((item) => item.variation)
            );
            const maxVariation = Math.max(
                ...data.map((item) => item.variation)
            );

            xMin = Math.floor(minVariation) - 3;
            xMax = Math.ceil(maxVariation) + 3;

            return data;
        }
    }, []);

    const { data } = usePhysicalFitnessData({
        athlete,
        dates,
        functionName: "get_report_chart_stats",
        handleDataModification: handleDataModification
    });
    return (
        <div className="flex flex-col items-center gap-3  justify-self-center w-5/6">
            <h4 className="text-blue-900">Report Changes</h4>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[xMin, xMax]} />
                    <YAxis dataKey="name" type="category" />
                    <Tooltip content={<CustomTooltip />} />
                    <ReferenceLine x={0} stroke="red" />
                    <Tooltip />
                    <Legend />
                    <Bar barSize={30} dataKey="variation" fill="#413ea0" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
