import { useState } from "react";
import { Modal, Input, Button, Message } from "rsuite";
import { updateTable } from "../services/update-table";
import dateValidation from "../utils/date-validation";

export default function UpdateTableFieldModal({
    closeModal,
    defaultValue,
    columnName,
    id,
    tableName
}) {
    const [error, setError] = useState(null);
    const [newValue, setNewValue] = useState(defaultValue);

    return (
        <Modal open={true} onClose={closeModal} backdrop={'static'}>
            <div className="flex flex-col gap-5">
                <Modal.Header>
                    <Modal.Title>Update value</Modal.Title>
                </Modal.Header>

                {error ? (
                    <Message showIcon type="error">
                        {error}
                    </Message>
                ) : (
                    <Message showIcon>{columnName}</Message>
                )}

                <Input
                    size="lg"
                    placeholder={defaultValue ? defaultValue : "New value"}
                    onChange={(value) => {
                        setNewValue(value);
                    }}
                />

                <div className="flex justify-end gap-2">
                    <Button appearance="default" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        appearance="primary"
                        onClick={async () => {
                            function execute() {
                                return new Promise(async (resolve, reject) => {
                                    try {
                                        const { data, error } =
                                            await updateTable({
                                                columnName: columnName,
                                                id: id,
                                                newValue:
                                                    columnName ===
                                                    "session_date"
                                                        ? dateValidation(
                                                              null,
                                                              newValue
                                                          )
                                                        : newValue,
                                                tableName: tableName
                                            });
                                        if (error)
                                            throw new Error(error.message);
                                        resolve(data);
                                    } catch (error) {
                                        reject(error.message);
                                    }
                                });
                            }
                            execute()
                                .then((data) => {
                                    closeModal();
                                    window.location.reload();
                                })
                                .catch((error) => {
                                    setError(error);
                                });
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
