export async function handleArrayAttributeName(array) {
    if (array.length !== 0) {
        return array.map((value) => {
            return {
                [value.attribute1_name]: value.attribute1_value,
                [value.attribute2_name]: value.attribute2_value,
                session_date: value.session_date
            };
        });
    }
    return array;
}

export function gapDates(array) {
    if (array.length !== 0) {
        const keys = Object.keys(array[0]);
        const transformedArray = [];
        const dates = array.map((obj) => obj.session_date);
        const minDate = new Date(
            Math.min(...dates.map((date) => new Date(date)))
        );
        const maxDate = new Date(
            Math.max(...dates.map((date) => new Date(date)))
        );

        const currentDate = new Date(minDate);
        while (currentDate <= maxDate) {
            const dateString = currentDate.toISOString().split("T")[0];
            const matchingObjs = array.filter(
                (obj) => obj.session_date === dateString
            );
            if (matchingObjs.length > 0) {
                transformedArray.push(...matchingObjs);
            } else {
                transformedArray.push({
                    [keys[0]]: null,
                    [keys[1]]: null,
                    session_date: dateString
                });
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return transformedArray;
    }
    return array;
}
