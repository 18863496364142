import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { fetchTableData } from "../services/fetchTable";
import Delete from "../pages/coach/athletes/Delete";
import { deleteById } from "../services/delete";

export default function useFetchTable({ tablename, setModal }) {
    const { profile, athlete } = useContext(AuthContext);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState(null);
    const [sortColumn, setSortColumn] = useState("updated_at");
    const [sortDirection, setSortDirection] = useState(false);

    useEffect(() => {
        async function execute() {
            const { data, count, error } = await fetchTableData({
                page,
                rowsPerPage,
                coach_id:
                    profile.role === "coach" ? profile.id : athlete.coach_id,
                tableName: tablename,
                playerLastName:
                    profile.role === "coach" ? null : athlete.player_last_name,
                playerPosition:
                    profile.role === "coach" ? null : athlete.player_position,
                searchText: searchText,
                sortColumn: sortColumn,
                sortDirection: sortDirection
            });
            if (error) console.log(error);
            setTotalRows(count);
            setData(data);
        }

        execute();
    }, [
        page,
        rowsPerPage,
        profile,
        tablename,
        athlete,
        searchText,
        sortColumn,
        sortDirection
    ]);

    function deleteRow(deletedRows) {
        setModal(
            <Delete
                closeModal={() => {
                    setModal(null);
                }}
                deleteFunction={() => {
                    return new Promise((resolve) => {
                        deletedRows.data.forEach(async (element) => {
                            const row = data[element.index];
                            const { error } = await deleteById({
                                id: row.id,
                                tableName: tablename
                            });
                            if (error) console.log(error);
                            resolve();
                        });
                    });
                }}
            />
        );
    }

    let searchTimer;

    function search(value) {
        // it will request just 750ms after user stop typing
        clearTimeout(searchTimer);
        searchTimer = setTimeout(async () => {
            setSearchText(value);
        }, 750);
    }

    function sort(changedColumn, direction) {
        setSortColumn(changedColumn);
        if (direction === "asc") setSortDirection(false);
        if (direction === "desc") setSortDirection(true);
    }

    const options = {
        filterType: "checkbox",
        responsive: "vertical",
        onRowsDelete: deleteRow,
        onSearchChange: search,
        rowsPerPage: rowsPerPage,
        onColumnSortChange: sort,
        rowsPerPageOptions: [10, 25, 50],
        count: totalRows,
        serverSide: false,
        onChangePage: (event) => {
            setPage(event);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setRowsPerPage(numberOfRows);
            setPage(0);
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };
    
    return {
        data,
        options
    };
}
