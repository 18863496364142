import { Tab, Tabs } from "@mui/material";

export default function Menu({ selectedTab, changeSelectedTab, sections }) {
    return (
        <div className="col-span-2 md:flex flex-col  print:hidden">
            <div className="flex items-center justify-center select-none">
                <img
                    src={"logo-simple.png"}
                    alt={""}
                    className="hidden md:block w-1/2 p-2"
                />
                <div className="hidden md:block">analytics</div>
            </div>
            <Tabs
                value={selectedTab}
                orientation={"vertical"}
                onChange={changeSelectedTab}
            >
                {sections.map(({ id, label }) => (
                    <Tab
                        key={id}
                        value={id}
                        label={label}
                        className="self-center justify-self-center"
                    />
                ))}
            </Tabs>
        </div>
    );
}
