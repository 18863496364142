import "rsuite/dist/rsuite.min.css";
import { DateRangePicker, InputPicker } from "rsuite";
import {
    attributeLeft,
    attributeRight
} from "../../coach/dashboard/attributes";

export default function Toolbar({
    setDates,
    attributes,
    setAttributes,
    setFunctionName
}) {
    return (
        <div className=" grid lg:grid-cols-3  2xl:grid-cols-4 gap-3  bg-white p-5 drop-shadow rounded">
            <DateRangePicker
                size="lg"
                onOk={async (event) => {
                    const formattedDateRange = event.map((date) =>
                        date.toISOString().substring(0, 10)
                    );

                    setDates({
                        startDate: formattedDateRange[0],
                        endDate: formattedDateRange[1]
                    });
                }}
            />

            <InputPicker
                defaultValue={attributes.left}
                size="lg"
                data={attributeLeft}
                onSelect={async (value) => {
                    setAttributes((prev) => ({ ...prev, left: value }));
                }}
            />

            <InputPicker
                defaultValue={attributes.right}
                size="lg"
                data={attributeRight}
                onSelect={(value) => {
                    setAttributes((prev) => ({
                        ...prev,
                        right: value
                    }));
                }}
            />

            <InputPicker
                placeholder="Group by"
                size="lg"
                data={[
                    {
                        label: "Month Median",
                        value: "get_composed_chart_by_month_median_data"
                    },
                    {
                        label: "Month Average",
                        value: "get_composed_chart_by_month_average_data"
                    },
                    {
                        label: "Day Median",
                        value: "get_composed_chart_by_day_median_data"
                    },
                    {
                        label: "Day Average",
                        value: "get_composed_chart_by_day_average_data"
                    }
                ]}
                onSelect={(value) => {
                    setFunctionName(value);
                }}
                onClean={() => {
                    setFunctionName("get_composed_chart_data");
                }}
            />
        </div>
    );
}
