export default function CustomTooltip({ active, payload, label }) {
    if (active && payload) {
        return (
            <div className=" p-3 bg-white ">
                <p className="text-gray-500">{`${label}`}</p>
                {payload.map((value, index) => {
                    return (
                        <p
                            key={index}
                            className={`text-black`}
                        >{`${value.dataKey}: ${value.value} %`}</p>
                    );
                })}
            </div>
        );
    }

    return null;
}
