import { insertTrainingLoad } from "../../../services/trainingload";
import Table from "../../../components/Table";
import useFetchTable from "../../../hooks/useFetchTable";
import { Button, SvgIcon } from "@mui/material";
import { useState } from "react";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
import HelpOutlineIcon from "@rsuite/icons/HelpOutline";
import HelpModal from "../../../components/HelpModal";
import useColumns from "./useColumns";
import ModalInsertXlsxTrainningLoad from "./ModalInsertXlsxTrainningLoad";

export default function TrainingLoad() {
    const [modal, setModal] = useState(null);
    const { data, options } = useFetchTable({
        tablename: "training_load",
        setModal: setModal
    });
    const { columns } = useColumns({
        setModal,
        closeModal: () => {
            setModal(null);
        }
    });

    return (
        <>
            <div className="mb-3 space-x-3">
                <Button
                    variant="contained"
                    style={{ background: "#0541B0" }}
                    onClick={() => {
                        setModal(
                            <ModalInsertXlsxTrainningLoad
                                closeModal={() => {
                                    setModal(null);
                                }}
                                insertFunction={insertTrainingLoad}
                            />
                        );
                    }}
                    startIcon={
                        <SvgIcon fontSize="small">
                            <ArrowUpOnSquareIcon />
                        </SvgIcon>
                    }
                >
                    Import
                </Button>
                <HelpOutlineIcon
                    onClick={() => {
                        setModal(
                            <HelpModal
                                closeModal={() => {
                                    setModal(null);
                                }}
                                columns={columns.slice(1)} // slice(1) to not send the "id" column
                                fileName={"training-load"}
                            />
                        );
                    }}
                    className="hover:bg-gray-200 rounded-md hover:cursor-pointer"
                    style={{ fontSize: "2em" }}
                />
            </div>
            {modal}
            <Table
                data={data}
                options={options}
                title={"Training Load"}
                columnsName={columns}
            />
        </>
    );
}
