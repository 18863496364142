export async function getNonExistentAthletes(existingAthletes, fileData) {
    const existingAthleteIds = new Set(
        existingAthletes.map(
            (athlete) =>
                `${athlete.player_last_name}:${athlete.player_position}`
        )
    );

    const newAthleteIds = new Set(); // Initialize a new Set

    const newAthletes = fileData
        .filter(
            (row) =>
                !existingAthleteIds.has(
                    `${row.player_last_name}:${row.player_position}`
                )
        )
        .map((athlete) => {
            const athleteId = `${athlete.player_last_name}:${athlete.player_position}`;
            if (newAthleteIds.has(athleteId)) {
                // Check if athleteId already exists in newAthletes
                return null; // If so, return null to skip adding the athlete to the newAthletes array
            } else {
                newAthleteIds.add(athleteId); // If not, add the athleteId to the newAthleteIds Set
                return {
                    player_last_name: athlete.player_last_name,
                    player_position: athlete.player_position
                };
            }
        })
        .filter(Boolean); // Remove any null values from the newAthletes array

    console.log("New Athletes:", newAthletes);
    return newAthletes;
}
