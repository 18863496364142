import { supabase } from "../config/supabase-client";

export const fetchProfile = async (id) => {
    const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", id)
        .maybeSingle();
    return { data, error };
};
