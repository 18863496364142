import { Modal, Button, Message } from "rsuite";
import { MuiFileInput } from "mui-file-input";
import { useContext, useState } from "react";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { convertXlsxToJson } from "../../../utils/handle-data-xlsx-csv";
import { AuthContext } from "../../../context/AuthContext";
import useSessionStorage from "../../../hooks/useSessionStorage";
import { fetchAthletes } from "../../../services/athletes";
import { getNonExistentAthletes } from "../training-load/helper-functions";
import NewAthleteFoundModal from "../training-load/NewAthleteFoundModal";

import styled from "styled-components";

export default function ModalInsertXlsxPhysicalFitness({ closeModal, insertFunction }) {
    const { profile } = useContext(AuthContext);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(undefined);
    const [dataToBeInserted, setDataToDoInserted] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { removeSessionStorage } = useSessionStorage("topAthletes");
    const [newAthletesModal, setNewAthletesModal] = useState(null);
    const [fileIsValid, setFileIsValid] = useState(true);

    const updateMessage = (severity, msg) => {
        setMessage({ severity, msg });
    };

    const handleSelectFile = (file) => {
        // this if is in case the user has one file already and then click the "x" button, so it will clean the message and file in the input
        if (!file) {
            setFile(null);
            setMessage(undefined);
            return;
        }

        setFile(file);
        setLoading(true);

        convertXlsxToJson(file)
            .then((dataToBeInserted) => {
                setDataToDoInserted(dataToBeInserted);
                return dataToBeInserted;
            })
            .then(async (dataToBeInserted) => {
                await getNewAthletes(dataToBeInserted);
                setLoading(false);
                setFileIsValid(false);
            })
            .catch((error) => {
                updateMessage("error", error.message);
                setLoading(false);
            });
    };

    const handleImport = async () => {
        setLoading(true);
    
        const mappedData = dataToBeInserted.map((item) => {
            const playerName = item.player_last_name;
            const playerPosition = item.player_position;
            const sessionDate = item.session_date;
            const weight = item.weight;
            const height = item.height;
            const fat = item.fat;
            const bmi = item.bmi;
            const fp = item.fp;
            const yoYoDist = item.yo_yo_dist;
            const yoYoVo = item.yo_yo_vo;
            const cmj = item.cmj;
            const sbj = item.sbj;
            const hopDist = item.hop_dist;
            return {
                player_last_name: playerName,
                player_position: playerPosition,
                session_date: sessionDate,
                weight: weight,
                height: height,
                fat: fat,
                bmi: bmi,
                fp: fp,
                yo_yo_dist: yoYoDist,
                yo_yo_vo: yoYoVo,
                cmj: cmj,
                sbj: sbj,
                hop_dist: hopDist,
                coach_id: profile.id
        };
    });


        const { error } = await insertFunction(mappedData);
    
        if (error) {
            console.log(error);
            updateMessage("error", error.message);
            setLoading(false);
        } else {
            removeSessionStorage();
            updateMessage("success", "Data inserted!");
            closeModal();
            window.location.reload();
        }
    };

    const getNewAthletes = async (dataToBeInserted) => {
        const { data: existingAthletes, error } = await fetchAthletes(
            profile.id
        );
        if (error) console.log(error);

        const newAthletes = await getNonExistentAthletes(
            existingAthletes,
            dataToBeInserted
        );

        if (newAthletes.length !== 0) {
            setNewAthletesModal(
                <NewAthleteFoundModal
                    newAthletes={newAthletes}
                    profile={profile}
                    closeModal={() => {
                        setNewAthletesModal(null);
                    }}
                />
            );
        }
    };

    return (
        <>
            {newAthletesModal}
            <Modal open={true} onClose={closeModal} backdrop={'static'}>
                <div className="flex flex-col gap-5 jus">
                    <Modal.Header>
                        <Modal.Title>Import XLSX File</Modal.Title>
                    </Modal.Header>

                    {message && (
                        <Message showIcon type={message.severity}>
                            {message.msg}
                        </Message>
                    )}

                    <CustomStyledWrapper>
                        <MuiFileInput
                            className="MuiInputAdornment-positionEnd"
                            value={file}
                            onChange={handleSelectFile}
                        />
                    </CustomStyledWrapper>

                    <div className="flex justify-end gap-2">
                        {loading && (
                            <div className="icon-example-list">
                                <SpinnerIcon
                                    pulse
                                    style={{ fontSize: "2em" }}
                                />
                            </div>
                        )}

                        <Button appearance="default" onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={handleImport}
                            disabled={fileIsValid}
                        >
                            Import
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

// to fix position of the file inside the MuiFileInput
const CustomStyledWrapper = styled.div`
    display: grid;

    & > div > div > div:nth-child(3) {
        margin-left: auto;
    }
`;
