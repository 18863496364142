import { Modal, Button } from "rsuite";

export default function HelpModal({ closeModal }) {
    return (
        <Modal open={true} onClose={closeModal} backdrop={'static'}>
            <Modal.Header>
                <Modal.Title>Session Pontuation</Modal.Title>
            </Modal.Header>
            <img src="session_pse_points.png" alt="" />
            <span className="flex justify-end">
                <Button appearance="default" onClick={closeModal}>
                    Got it
                </Button>
            </span>
        </Modal>
    );
}
