import "rsuite/dist/rsuite.min.css";
import { DateRangePicker, InputPicker, SelectPicker } from "rsuite";
import { attributeLeft, attributeRight } from "./attributes";
import useAthletes from "../../../hooks/useAthletes";

export default function Toolbar({
    setAthlete,
    setDates,
    attributes,
    setAttributes,
    setFunctionName
}) {
    const { athletes } = useAthletes();
    return (
        <div className=" grid lg:grid-cols-3  2xl:grid-cols-4 gap-3  bg-white p-5 drop-shadow rounded">
            <SelectPicker
                placeholder="Select athlete"
                size="lg"
                data={athletes.map((item) => ({
                    label: `${item.player_last_name} - ${item.player_position}`,
                    value: JSON.stringify(item)
                }))}
                onSelect={async (event) => {
                    setAthlete(JSON.parse(event));
                }}
            />

            <DateRangePicker
                size="lg"
                //defaultValue={[new Date("2019-07-01"), new Date("2019-08-01")]}
                onOk={async (event) => {
                    const formattedDateRange = event.map((date) =>
                        date.toISOString().substring(0, 10)
                    );

                    setDates({
                        startDate: formattedDateRange[0],
                        endDate: formattedDateRange[1]
                    });
                }}
            />

            <InputPicker
                defaultValue={attributes.left}
                size="lg"
                data={attributeLeft}
                onSelect={async (value) => {
                    setAttributes((prev) => ({ ...prev, left: value }));
                }}
                onClean={() => {
                    setAttributes((prev) => ({
                        ...prev,
                        left: null
                    }));
                }}
            />

            <InputPicker
                defaultValue={attributes.right}
                size="lg"
                data={attributeRight}
                onSelect={(value) => {
                    setAttributes((prev) => ({
                        ...prev,
                        right: value
                    }));
                }}
                onClean={() => {
                    setAttributes((prev) => ({
                        ...prev,
                        right: null
                    }));
                }}
            />

            <InputPicker
                placeholder="Group by"
                size="lg"
                data={[
                    {
                        label: "Month Median",
                        value: "get_composed_chart_by_month_median_data"
                    },
                    {
                        label: "Month Average",
                        value: "get_composed_chart_by_month_average_data"
                    },
                    {
                        label: "Month Standard Deviation",
                        value: "get_composed_chart_by_month_standard_deviation"
                    },
                    {
                        label: "Day Median",
                        value: "get_composed_chart_by_day_median_data"
                    },
                    {
                        label: "Day Average",
                        value: "get_composed_chart_by_day_average_data"
                    },
                    {
                        label: "Day Standard Deviation",
                        value: "get_composed_chart_by_day_standard_deviation"
                    }

                ]}
                onSelect={(value) => {
                    setFunctionName(value);
                }}
                onClean={() => {
                    setFunctionName("get_composed_chart_data");
                }}
            />
        </div>
    );
}
