import { useEffect, useState } from "react";
import { supabase } from "../config/supabase-client";

export default function usePlayerPosition() {
    const [data, setData] = useState();

    useEffect(() => {
        supabase
            .from("athletes")
            .select("player_position", { distinct: true })

            .then(({ data, error }) => {
                if (error) {
                    console.log(error);
                    return;
                }
                const positions = data.map((row) => row.player_position);
                const uniquePositions = [...new Set(positions)];
                setData(uniquePositions);
            });
    }, []);

    return {
        data
    };
}
