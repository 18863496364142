import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "../config/supabase-client";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export default function AuthPage() {
    const navigate = useNavigate();
    const { session } = useContext(AuthContext);

    useEffect(() => {
        if (session) {
            navigate("/home");
        }
    }, [session, navigate]);

    if (!session) {
        return (
            <main className="w-screen h-screen flex  items-center justify-center">
                <div className="container md:w-1/2 mx-auto">
                    <div className="row-span-1 col-start-2 col-span-4">
                        <img
                            alt=""
                            className="rounded-t-lg w-60 mx-auto py-4"
                            src={"logo-simple.png"}
                        />
                    </div>
                    <Auth
                        supabaseClient={supabase}
                        appearance={{ theme: ThemeSupa }}
                        providers={[]}
                    />
                </div>
            </main>
        );
    } else {
        return null;
    }
}
