import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    Brush
} from "recharts";

import "rsuite/dist/rsuite.min.css";
import useTrainingLoadData from "../hooks/useTrainingLoadData";

export default function TrainingLoadComposedChart({
    athlete,
    attributes,
    dates,
    functionName
}) {
    const { data } = useTrainingLoadData({
        dates,
        athlete,
        attributes,
        initalData: [],
        functionName: functionName,
        showAttributesName: true,
        showEmptyDates:
            functionName === "get_composed_chart_by_day_median_data" ||
            functionName === "get_composed_chart_by_day_average_data" ||
            functionName === "get_composed_chart_by_day_standard_deviation" ||
            functionName === "get_composed_chart_data"
                ? true
                : false
    });

    return (
        <div className="animate__animated animate__fadeInUp space-y-10 flex-grow">
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart data={data}>
                    <XAxis
                        dataKey="session_date"
                        tickFormatter={(session_date) => {
                            const date = new Date(session_date);
                            const day = date.getDate();
                            const month = date.toLocaleString("en-UK", {
                                month: "short"
                            });

                            return `${month}/${day}`;
                        }}
                        angle={25}
                    />

                    <YAxis
                        yAxisId="left"
                        type="number"
                        dataKey={`${attributes.left}`}
                        stroke="#00bf36"
                    />
                    <YAxis
                        yAxisId="right"
                        type="number"
                        dataKey={`${attributes.right}`}
                        unit="m"
                        orientation="right"
                        stroke="#413ea0"
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Legend />
                    <Tooltip />
                    <Bar
                        yAxisId="right"
                        dataKey={`${attributes.right}`}
                        barSize={20}
                        fill="#413ea0"
                    />
                    <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey={`${attributes.left}`}
                        stroke="#00bf36"
                        connectNulls
                    />

                    {/* The brush zie will be always 20% of total of entries of athlete data */}
                    {data ? (
                        <Brush
                            height={30}
                            endIndex={Math.floor(data.length * 0.8)}
                            stroke="#8884d8"
                        />
                    ) : null}
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
}
