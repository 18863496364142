import { useState } from "react";
import UpdateTableFieldModal from "../../../components/UpdateTableFieldModal";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import FileUploadIcon from "@rsuite/icons/FileUpload";

export default function useColumns({ setModal, closeModal }) {
    const { profile } = useContext(AuthContext);

    function customBodyRender(value, tableMeta) {
        return (
            <span
                className="hover:cursor-pointer hover:underline  "
                onClick={() => {
                    let id = tableMeta.rowData[0];
                    let columnName = tableMeta.columnData.name;
                    setModal(
                        <UpdateTableFieldModal
                            closeModal={closeModal}
                            defaultValue={value}
                            columnName={columnName}
                            id={id}
                            tableName={"training_load"}
                        />
                    );
                }}
            >
                {value ? (
                    value
                ) : (
                    <span>
                        N/A <FileUploadIcon />{" "}
                    </span>
                )}
            </span>
        );
    }

    function iDontKnowHowToCallIt(value) {
        return <span>{value ? value : "N/A"}</span>;
    }

    const [columns] = useState([
        {
            name: "id",
            label: "Id",
            type: "text",
            options: {
                display: false,
                viewColumns: false,
                filter: false
            }
        },
        {
            name: "player_last_name",
            label: "Player Last Name",
            type: "text",
            options: {
                display: true,
                sort: false
            }
        },
        {
            name: "player_position",
            label: "Player Position",
            type: "text (if no position assigned, type 'none')",
            options: {
                display: true,
                sort: false
            }
        },
        {
            name: "session_date",
            label: "Session Date",
            type: "text (yyyy-mm-dd)",
            options: {
                display: true,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "session_duration",
            label: "Session Duration",
            type: "number (mins)",
            options: {
                display: true,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "session_pontuation",
            label: "Session Pontuation",
            type: "number",
            options: {
                display: true,
                customBodyRender:
                    profile.role === "coach"
                        ? iDontKnowHowToCallIt
                        : customBodyRender
            }
        },
        {
            name: "session_pse",
            label: "Session PSE",
            type: "number",
            options: {
                display: true,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "session_day_of_week",
            label: "Session day of week",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "session_week_number",
            label: "Session week number",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "session_type",
            label: "Session type",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "drill_date",
            label: "Drill date",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "drill_title",
            label: "Drill Title",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "distance_total",
            label: "Distance Total",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "distance_per_min",
            label: "Distance per min",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "speed_intensity",
            label: "Speed intensity",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "high_speed_running",
            label: "High speed running",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "high_speed_running_per_minute",
            label: "Hight speed running per minute",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "distance_z4_to_z6",
            label: "Distance z4 to z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "sprints",
            label: "Sprints",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "distance_z6",
            label: "Distance z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "high_metabolic_power_distance",
            label: "High metabolic power distance",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "high_metabolic_power_distance_per_minute",
            label: "High metabolic power distance per minute",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "average_metabolic_power",
            label: "Average metabolic power",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "dynamic_stress_load",
            label: "Dynamic stress load",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "dynamic_stress_load_z5_to_z6",
            label: "Dynamic stress load z5 to z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "accelerations",
            label: "Acclerations",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "accelerations_z3_to_z6",
            label: "Acclerations z3 to z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "accelerations_z5_to_z6",
            label: "Acclerations z5 to z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "decelerations",
            label: "Decelerations",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "deceleration_z3_to_z6",
            label: "Deceleration z3 to z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "deceleration_z5_to_z6",
            label: "Deceleration z5 to z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "impacts",
            label: "Impacts",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "impacts_z3_to_z6",
            label: "Impacts z3 to z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "impacts_z5_to_z6",
            label: "Impacts z5 to z6",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "heart_rate_exertion",
            label: "Heart rate exertion",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "time_in_red_zone",
            label: "Time in red zone",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "time_in_red_zone_per_minute",
            label: "Time in red zone per minute",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "fatigue_index",
            label: "Fatigue index",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "percieved_exertion",
            label: "percieved_exertion",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "equivalent_metabolic_distance",
            label: "Equivalent metabolic distance",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "metabolic_distance_zonal",
            label: "Metabolic distance zonal",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "max_hr",
            label: "Max hr",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "max_speed",
            label: "Max speed",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "drill_start",
            label: "Drill start",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "drill_end",
            label: "Drill end",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "energyexpenditure",
            label: "Energyexpenditure",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "time_in_zone_6",
            label: "Time in zone 6",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "duration_decimal",
            label: "Duration decimal",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "explosive_distance",
            label: "Explosive distance",
            type: "number",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "includeexclude",
            label: "Include Exclude",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "season",
            label: "Season",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        },
        {
            name: "session_type_override",
            label: "Session type override",
            type: "text",
            options: {
                display: false,
                customBodyRender:
                    profile.role === "coach"
                        ? customBodyRender
                        : iDontKnowHowToCallIt
            }
        }
    ]);
    return {
        columns
    };
}
