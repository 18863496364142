import { createContext, useEffect, useState } from "react";
import { supabase } from "../config/supabase-client";
import { fetchProfile } from "../services/profile";
import { fetchAthleteByUserEmail } from "../services/athletes";
import useLocalStorage from "../hooks/useLocalStorage";
import useSessionStorage from "../hooks/useSessionStorage";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [profile, setProfile] = useState(null);
    const [athlete, setAthlete] = useState(null);

    const { getLocalStorage } = useLocalStorage(
        "sb-afktqfeknwogbmfprinz-auth-token"
    );

    const {
        getSessionStorage: getSession,
        setSessionStorage: setSessionStorageSession
    } = useSessionStorage("session");
    const {
        getSessionStorage: getProfile,
        setSessionStorage: setSessionStorageProfile
    } = useSessionStorage("profile");
    const {
        getSessionStorage: getAthlete,
        setSessionStorage: setSessionStorageAthlete
    } = useSessionStorage("athlete");

    useEffect(() => {
        // if (getLocalStorage() && getSession() && getProfile()) {
        //     console.log(getLocalStorage());
        //     setSession(getSession());
        //     setProfile(getProfile());
        //     setAthlete(getAthlete());
        //     return;
        // }

        supabase.auth
            .getSession()
            .then(({ data: { session } }) => {
                if (!session) return;
                setSessionStorageSession(session);
                setSession(session);
                return session;
            })
            .then(async (session) => {
                if (!session) return;
                const { data: profile, error } = await fetchProfile(
                    session.user.id
                );
                if (error) return;
                setSessionStorageProfile(profile);
                setProfile(profile);
                return profile;
            })
            .then(async (profile) => {
                if (!profile || profile.role !== "user") {
                    return;
                }
                const { data: athlete, error } = await fetchAthleteByUserEmail(
                    profile.email
                );
                if (error) return;
                setSessionStorageAthlete(athlete);
                setAthlete(athlete);
            });

        const {
            data: { subscription }
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        return () => subscription.unsubscribe();
    }, [session?.user.id]);

    return (
        <AuthContext.Provider value={{ session, profile, athlete }}>
            {children}
        </AuthContext.Provider>
    );
};
