import Dashboard from "../../pages/coach/dashboard/Dashboard";
import Athletes from "../../pages/coach/athletes/Athletes";
import TrainingLoad from "../../pages/coach/training-load/TrainingLoad";
import PeoplesIcon from "@rsuite/icons/Peoples";
import DashboardIcon from "@rsuite/icons/Dashboard";
import TableIcon from "@rsuite/icons/Table";
import PhysicalFitness from "../../pages/coach/physical-fitness/PhysicalFitness";

const sectionsCoach = [
    {
        id: "Dashboard",
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <DashboardIcon style={{ marginRight: "5px" }} />
                <span>Dashboard</span>
            </div>
        ),
        content: <Dashboard />
    },
    {
        id: "Athletes",
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <PeoplesIcon style={{ marginRight: "5px" }} />
                <span>Athletes</span>
            </div>
        ),
        content: <Athletes />
    },
    {
        id: "TrainingLoad",
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <TableIcon style={{ marginRight: "5px" }} />
                <span>Training Load</span>
            </div>
        ),
        content: <TrainingLoad />
    },
    {
        id: "PhysicalFitness",
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <TableIcon style={{ marginRight: "5px" }} />
                <span>Physical Fitness</span>
            </div>
        ),
        content: <PhysicalFitness />
    }
];

export default sectionsCoach;
