import Table from "../../../components/Table";
import { insertPhysicalFitness } from "../../../services/physicalfitness";
import useFetchTable from "../../../hooks/useFetchTable";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
import { Button, SvgIcon } from "@mui/material";
import { useState } from "react";
import HelpOutlineIcon from "@rsuite/icons/HelpOutline";
import HelpModal from "../../../components/HelpModal";
import useColumns from "./useColumns";
import ModalInsertXlsxPhysicalFitness from "./ModalInsertXlsxPhysicalFitness";

export default function PhysicalFitness() {
    const [modal, setModal] = useState(null);
    const { data, options } = useFetchTable({
        tablename: "physical_fitness",
        setModal: setModal
    });
    const { columns } = useColumns({
        setModal,
        closeModal: () => {
            setModal(null);
        }
    });

    return (
        <>
            <div className="mb-3 space-x-3">
                <Button
                    variant="contained"
                    style={{ background: "#0541B0" }}
                    onClick={() => {
                        setModal(
                            <ModalInsertXlsxPhysicalFitness
                            backdrop={'static'}
                                closeModal={() => {
                                    setModal(null);
                                }}
                                insertFunction={insertPhysicalFitness}
                            />
                        );
                    }}
                    startIcon={
                        <SvgIcon fontSize="small">
                            <ArrowUpOnSquareIcon />
                        </SvgIcon>
                    }
                >
                    Import
                </Button>
                <HelpOutlineIcon
                    onClick={() => {
                        setModal(
                            <HelpModal
                            backdrop={'static'}
                                closeModal={() => {
                                    setModal(null);
                                }}
                                columns={columns}
                                fileName={"physical-fitness"}
                            />
                        );
                    }}
                    className="hover:bg-gray-200 rounded-md hover:cursor-pointer"
                    style={{ fontSize: "2em" }}
                />
            </div>
            {modal}
            <Table
                data={data}
                options={options}
                title={"Physical Fitness"}
                columnsName={columns}
            />
        </>
    );
}
