import { useState } from "react";
import AssociateEmailModal from "./AssociateEmailModal";
import LinkIcon from "@mui/icons-material/Link";
import EditAthleteModal from "./EditAthleteModal";
import EditIcon from "@rsuite/icons/Edit";

export default function useColumns({ setModal }) {
    const [columns] = useState([
        {
            name: "user_email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const athlete = {
                        user_email: tableMeta.rowData[0],
                        player_last_name: tableMeta.rowData[1],
                        player_position: tableMeta.rowData[2],
                        birthday: tableMeta.rowData[3]
                    };

                    return (
                        <>
                            {athlete.user_email ? (
                                athlete.user_email
                            ) : (
                                <div>
                                    <button
                                        onClick={() => {
                                            setModal(
                                                <AssociateEmailModal
                                                    closeModal={() => {
                                                        setModal(null);
                                                    }}
                                                    athlete={athlete}
                                                />
                                            );
                                        }}
                                    >
                                        <LinkIcon color="primary" />
                                        Associate to a user
                                    </button>
                                </div>
                            )}
                        </>
                    );
                }
            }
        },

        {
            name: "player_last_name",
            label: "Player Last Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "player_position",
            label: "Player Position"
        },
        {
            name: "birthday",
            label: "Birthday",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value ? value : "N/A";
                }
            }
        },
        {
            name: "edit",
            label: "Edit athlete",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const athlete = {
                        user_email: tableMeta.rowData[0],
                        player_last_name: tableMeta.rowData[1],
                        player_position: tableMeta.rowData[2],
                        birthday: tableMeta.rowData[3]
                    };
                    return (
                        <button
                            onClick={() => {
                                setModal(
                                    <EditAthleteModal
                                        closeModal={() => {
                                            setModal(null);
                                        }}
                                        athlete={athlete}
                                    />
                                );
                            }}
                        >
                            <EditIcon style={{ fontSize: "1.5em" }} />
                        </button>
                    );
                }
            }
        }
    ]);
    return {
        columns
    };
}
