import { Modal, Input, Button, Message } from "rsuite";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { supabase } from "../../../config/supabase-client";

export default function AssociateEmailModal({ closeModal, athlete }) {
    const { profile } = useContext(AuthContext);
    const [selectedEmail, setSelectedEmail] = useState("");
    const [error, setError] = useState("");

    async function handleSaveAssociate() {
        const { error } = await supabase
            .from("athletes")
            .update({
                user_email: selectedEmail
            })
            .eq("player_last_name", athlete.player_last_name)
            .eq("player_position", athlete.player_position)
            .eq("coach_id", profile.id);
        if (error) {
            setError("Email not found or already taken");
            return;
        }
        closeModal();
        window.location.reload();
    }

    return (
        <Modal open={true} onClose={closeModal} backdrop={'static'}>
            <div className="flex flex-col gap-5">
                <Modal.Header>
                    <Modal.Title>Associate a Email</Modal.Title>
                </Modal.Header>

                {error && (
                    <Message showIcon type="error">
                        {error}
                    </Message>
                )}

                <Input
                    size="lg"
                    placeholder="Email"
                    onChange={(value) => {
                        setSelectedEmail(value);
                    }}
                />

                <div className="flex justify-end gap-2">
                    <Button appearance="default" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button appearance="primary" onClick={handleSaveAssociate}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
