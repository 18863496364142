import { supabase } from "../config/supabase-client";

export const fetchAthletes = async (coach_id) => {
    const { data, error } = await supabase
        .from("athletes")
        .select("*")
        .eq("coach_id", coach_id)
        .order("updated_at", { ascending: false });
    if (error) throw new Error(error.message);
    return { data, error };
};

export const insertAthletes = async (data) => {
    const { result, error } = await supabase.from("athletes").insert(data);
    if (error) throw new Error(error.message);
    return result;
};

export const fetchAthleteByUserEmail = async (userEmail) => {
    const { data, error } = await supabase
        .from("athletes")
        .select("*")
        .eq("user_email", userEmail)
        .maybeSingle();
    return { data, error };
};
