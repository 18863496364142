import { Modal, Button } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

export default function HelpModal({ closeModal, columns = [], fileName }) {
    function downloadXlsxFile() {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet([], {
            header: columns.map((value) => {
                return value.name;
            })
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(blob, fileName + fileExtension);
    }

    return (
        <Modal open={true} onClose={closeModal} backdrop={'static'}>
            <div className="flex mb-3 items-center justify-between">
                <h5>File columns: </h5>
                <Button
                    color="blue"
                    appearance="primary"
                    startIcon={<FileDownloadIcon />}
                    onClick={downloadXlsxFile}
                >
                    Download XLSX
                </Button>
            </div>
            <div className="flex flex-col gap-2 bg-teal-50 p-2">
                {columns.map((field) => {
                    return (
                        <p key={field.label} className="font-semibold text-lg">
                            {field.name} -{" "}
                            <span className="text-blue-400">{field.type}</span>
                        </p>
                    );
                })}
            </div>
            <div className="flex justify-end gap-2 mt-3">
                <Button appearance="default" onClick={closeModal}>
                    Got it
                </Button>
            </div>
        </Modal>
    );
}
