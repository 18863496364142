import { useState } from "react";
import Table from "../../../components/Table";
import useFetchTable from "../../../hooks/useFetchTable";
import useColumns from "./useColumns";
import InsertAthleteModal from "./InsertAthleteModal";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { Button } from "@mui/material";

export default function Athletes() {
    const [modal, setModal] = useState(null);
    const { data, options } = useFetchTable({
        tablename: "athletes",
        setModal: setModal
    });
    const { columns } = useColumns({ setModal });

    return (
        <>
            <div className="mb-3">
                <Button
                    variant="contained"
                    style={{ background: "#0541B0" }}
                    onClick={() => {
                        setModal(
                            <InsertAthleteModal
                                closeModal={() => {
                                    setModal(null);
                                }}
                            />
                        );
                    }}
                >
                    <PersonAddIcon
                        sx={{
                            fontSize: 24,
                            marginRight: 2
                        }}
                    />
                    New Athletes
                </Button>
            </div>

            {modal}
            <Table
                data={data}
                options={options}
                title={"Athletes"}
                columnsName={columns}
            />
        </>
    );
}
