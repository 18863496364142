import {
    BarChart,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    ResponsiveContainer,
    Tooltip
} from "recharts";
import "rsuite/dist/rsuite.min.css";
import useTrainingLoadData from "../hooks/useTrainingLoadData";

export default function TrainingLoadAverageBarChart({
    athlete,
    attributes,
    dates
}) {
    const { data } = useTrainingLoadData({
        dates,
        athlete,
        attributes,
        initalData: [
            {
                attribute_name: attributes.left
            },
            { attribute_name: attributes.right }
        ],
        functionName: "get_avg_chart_stats"
    });

    return (
        <div className="space-y-10">
            <h4 className="text-blue-900 text-center">Average of athletes</h4>
            <ResponsiveContainer
                width={"100%"}
                className="justify-self-center self-center"
                height={400}
            >
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="attribute_name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar barSize={30} dataKey={"avg_athlete"} fill="#413ea0" />
                    <Bar
                        barSize={30}
                        dataKey={"avg_all_athlete"}
                        fill="#00bf36"
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
