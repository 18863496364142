import { useContext, useEffect, useState } from "react";
import sectionsCoach from "./sectionsCoach";
import Menu from "./Menu";
import Content from "./Content";
import { AuthContext } from "../../context/AuthContext";
import sectionsUser from "./sectionsUser";

export default function Layout() {
    const { profile } = useContext(AuthContext);

    const [selectedTab, setSelectedTab] = useState(undefined);
    const [sections, setSections] = useState(undefined);

    useEffect(() => {
        if (profile) {
            if (profile.role === "coach") {
                setSelectedTab(sectionsCoach[0].id);
                setSections(sectionsCoach);
            }
            if (profile.role === "user") {
                setSelectedTab(sectionsUser[0].id);
                setSections(sectionsUser);
            }
        }
    }, [profile]);

    return (
        <main className="md:grid md:grid-cols-12 min-h-screen max-w-[100vw]  print:flex  print:h-max print:w-max print:m-0">
            {selectedTab && (
                <>
                    <Menu
                        selectedTab={selectedTab}
                        changeSelectedTab={(e, v) => setSelectedTab(v)}
                        sections={sections}
                    />
                    <Content selected={selectedTab} sections={sections} />
                </>
            )}
        </main>
    );
}
