import DashboardIcon from "@rsuite/icons/Dashboard";
import Dashboard from "../../pages/user/dashboard/Dashboard";
import TrainingLoad from "../../pages/user/training-load/TrainingLoad";
import TableIcon from "@rsuite/icons/Table";
import PhysicalFitness from "../../pages/user/physical-fitness/PhysicalFitness";

const sectionsUser = [
    {
        id: "Dashboard",
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <DashboardIcon style={{ marginRight: "5px" }} />
                <span>Dashboard</span>
            </div>
        ),
        content: <Dashboard />
    },
    {
        id: "TrainingLoad",
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <TableIcon style={{ marginRight: "5px" }} />
                <span>Training Load</span>
            </div>
        ),
        content: <TrainingLoad />
    },
    {
        id: "PhysicalFitness",
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <TableIcon style={{ marginRight: "5px" }} />
                <span>Physical Fitness</span>
            </div>
        ),
        content: <PhysicalFitness />
    }
];

export default sectionsUser;
