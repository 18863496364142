import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Button } from "rsuite";
import styled from "styled-components";
import * as XLSX from "xlsx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export default function Table({ data, options, title, columnsName = [] }) {
  const [selectedRows, setSelectedRows] = useState([]);

  const onRowsSelect = (currentRowsSelected, allRowsSelected) => {
    setSelectedRows(allRowsSelected.map((row) => data[row.dataIndex]));
  };

  const handleExportToExcel = () => {
    if (selectedRows.length === 0) {
      alert("Please select at least one item to export.");
      return;
    }

    const ws = XLSX.utils.json_to_sheet(selectedRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${title}.xlsx`);
  };

  return (
    <CustomStyledWrapper>
      <Button
        variant="contained"
        style={{ background: "#0541B0", color: "white", marginBottom: "10px" }}
        onClick={handleExportToExcel}
      >
        <CloudDownloadIcon
          sx={{
            fontSize: 24,
            marginRight: 2,
          }}
        />
        Export to Excel
      </Button>
      <MUIDataTable
        title={title}
        data={data}
        columns={columnsName}
        options={{
          ...options,
          selectableRows: "multiple",
          onRowsSelect,
        }}
      />
    </CustomStyledWrapper>
  );
}

// para centralizar o texto na MUIDatatable
const CustomStyledWrapper = styled.div`
  td {
    text-align: center;
  }

  th > span {
    justify-content: center;
  }

  th > div {
    text-align: center;
  }

  button {
    margin: 0;
  }
`;
