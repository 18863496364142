import { createBrowserRouter } from "react-router-dom";
import AuthPage from "../pages/Auth";
import Layout from "../layout/dashboard/Layout";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthPage />,
        children: []
    },
    {
        path: "/home",
        element: <Layout />,
        children: []
    }
]);
